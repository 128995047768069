import React from 'react';
// import { config } from '@triare/auth-redux';
import Simple from '../../components/Layout/Simple';
import FormContent from '../../components/Pages/Form';

// const { displayName } = config;

export default function Form(): React.ReactNode | null {
  document.title = 'Order Form';

  return (
    <Simple>
      <FormContent />
    </Simple>

  );
}
