import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.REACT_APP_ENV === 'local',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          contactName: 'Contact name',
          company: 'Company',
          mobileNumber: 'Mobile number',
          email: 'Email',
        },
      },
      de: {
        translation: {
          email: 'Email-de',
          signUp: {
            google: 'Sign up with google',
          },
        },
      },
    },
  });

export default i18n;
