import {
  AirIcon, CourierIcon, RailIcon, SeaIcon, TruckIcon,
} from '../../../../Common/Icon';

export const shipperFields = [
  'contactName',
  'company',
  'phone',
  'email',
  'country',
  'address1',
  'address2',
  'postalCode',
  'city',
  'state',
];
export const pickupFields = [
  'pickupContactName',
  'pickupCompany',
  'pickupPhone',
  'pickupEmail',
  'pickupCountry',
  'pickupAddress1',
  'pickupAddress2',
  'pickupPostalCode',
  'pickupCity',
  'pickupState',
];
export const importerFields = [
  'contactName',
  'company',
  'phone',
  'email',
  'country',
  'address1',
  'address2',
  'postalCode',
  'city',
  'state',
];

export const deliveryFields = [
  'deliveryContactName',
  'deliveryCompany',
  'deliveryPhone',
  'deliveryEmail',
  'deliveryCountry',
  'deliveryAddress1',
  'deliveryAddress2',
  'deliveryPostalCode',
  'deliveryCity',
  'deliveryState',
];

export enum ModeOfTransportEnum {
  courier = 'Courier',
  road = 'Road Freight (ADR)',
  rail = 'Rail',
  air = 'Air Freight (IATA)',
  sea = 'Sea Freight (IMDG-IMO)',
}

export const getIcon = (name: string) => {
  switch (name) {
    case ModeOfTransportEnum.courier:
      return <CourierIcon />;
    case ModeOfTransportEnum.air:
      return <AirIcon />;
    case ModeOfTransportEnum.road:
      return <TruckIcon />;
    case ModeOfTransportEnum.sea:
      return <SeaIcon />;
    default:
      return <RailIcon />;
  }
};
