import './index.scss';

import Routes from './routes';
import AntdConfigProvider from './AntdConfigProvider';

export default function App(): React.ReactNode {
  return (
    <AntdConfigProvider>
      <Routes />
    </AntdConfigProvider>
  );
}
