import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { type NamePath, Rule } from 'rc-field-form/lib/interface';
import { SimpleOption } from '../../../../types';
import { useMessageError } from '../../../../hooks/common';
import Select, { SelectFloatProps } from '../../../Pages/Form/Components/Float/Select';
import { ResponseForSelect, useBillingOptionsGet } from '../../../../hooks/api/order';
import { FetchGet } from '../../../../hooks/fetch';

interface SelectBillingCodeTypeProps extends Omit<SelectFloatProps, 'options'> {
  fetch?: FetchGet<any, any, any, ResponseForSelect[]>;
  rules?: Rule[];
  label: string;
  name: NamePath<string>;
  children?: React.ReactNode;
  required?: boolean;
  fetchPath: 'billing-types' | 'billing-codes';
}

function SelectBillingCodeType({
  label, rules, name, required, children, fetchPath, ...props
}: SelectBillingCodeTypeProps) {
  const billingCodesGet = useBillingOptionsGet(undefined, fetchPath);
  const [selectOptions, setSelectOptions] = useState<SimpleOption[]>([]);

  useMessageError([billingCodesGet]);

  useEffect(() => {
    if (billingCodesGet.data) {
      setSelectOptions(billingCodesGet.data);
    }
  }, [billingCodesGet.data]);

  return (
    <Form.Item
      required={required}
      name={name}
      rules={rules}
      extra={children}
    >
      <Select
        label={label || 'Billing code'}
        loading={billingCodesGet.loading}
        options={selectOptions}
        search
        {...props}
      />
    </Form.Item>
  );
}

export default SelectBillingCodeType;
