import React from 'react';
import { Layout as AntdLayout } from 'antd';
import Header from '../Header';
import Footer from '../Footer';

import styles from './index.module.scss';

interface SimpleProps {
  children: React.ReactNode
}

export default function Simple({ children }: SimpleProps): React.ReactNode {
  return (
    <AntdLayout style={{ minHeight: '100vh' }}>
      <AntdLayout.Header className={styles.header}>
        <Header />
      </AntdLayout.Header>
      <AntdLayout.Content className={styles.content}>
        {children}
      </AntdLayout.Content>
      <AntdLayout.Footer className={styles.footer}>
        <Footer />
      </AntdLayout.Footer>
    </AntdLayout>
  );
}
