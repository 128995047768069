import { Form, Radio } from 'antd';
import styles from './index.module.scss';
import {
  AirIcon, CourierIcon, RailIcon, SeaIcon, TruckIcon,
} from '../../../../Common/Icon';
import { ModeOfTransportEnum } from '../../Steps/Overview/helper';

export default function RadioBtns(): React.ReactNode | null {
  return (
    <Form.Item
      name="shippingType"
      rules={[{ required: true }]}
    >
      <Radio.Group className={styles.radio}>
        {/* <Radio.Button value={ModeOfTransportEnum.courier}>
          <div className={styles.radioWrap}>
            <CourierIcon />
            Courier
          </div>
        </Radio.Button> */}
        <Radio.Button value={ModeOfTransportEnum.road}>
          <div className={styles.radioWrap}>
            <TruckIcon />
            Truck
          </div>
        </Radio.Button>
        <Radio.Button value={ModeOfTransportEnum.air}>
          <div className={styles.radioWrap}>
            <AirIcon />
            Air
          </div>
        </Radio.Button>
        <Radio.Button value={ModeOfTransportEnum.sea}>
          <div className={styles.radioWrap}>
            <SeaIcon />
            Sea
          </div>
        </Radio.Button>
        <Radio.Button value={ModeOfTransportEnum.rail}>
          <div className={styles.radioWrap}>
            <RailIcon />
            Rail
          </div>
        </Radio.Button>

      </Radio.Group>
    </Form.Item>
  );
}
