import React from 'react';
import TrackingProvider from './Context';
import Wrapper from './Wrapper';

import styles from './index.module.scss';

export default function Tracking(): React.ReactNode | null {
  return (
    <TrackingProvider>
      <div className={styles.wrapper}>
        <Wrapper />
      </div>
    </TrackingProvider>
  );
}
