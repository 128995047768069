import React, { ReactElement } from 'react';
import { Route, RouteElements, RouteRoles } from './index';

import Form from '../pages/Form';

interface PrivateRouteProps {
  children?: ReactElement | React.ReactNode

  item?: Route

  element?: React.ReactNode

  elements?: RouteElements
}

function PrivateRoute({
  children, item, element, elements,
}: PrivateRouteProps): React.ReactNode | null | undefined {
 
  if (element) {
    return element;
  }

  return children;
}

PrivateRoute.defaultProps = {
  element: undefined,
  elements: undefined,
  item: undefined,
  children: undefined,
};

export default PrivateRoute;
