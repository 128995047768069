import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { type NamePath, Rule } from 'rc-field-form/lib/interface';
import { useCountryGet } from '../../../../hooks/api/country';
import { SimpleOption } from '../../../../types';
import { useMessageError } from '../../../../hooks/common';
import Select, { SelectFloatProps } from '../../../Pages/Form/Components/Float/Select';

interface SelectCountryProps extends Omit<SelectFloatProps, 'options'> {
  rules?: Rule[];
  label: string;
  name: NamePath<string>;
  children?: React.ReactNode;
  required?: boolean;
  valueKey?: 'name' | 'code';
}

function SelectCountry({
  label, rules, name, required, children, valueKey = 'name', ...props
}: SelectCountryProps) {
  const countryGet = useCountryGet();
  const [countryOptions, setCountryOptions] = useState<SimpleOption[]>([]);

  useMessageError([countryGet]);

  useEffect(() => {
    if (countryGet.data) {
      setCountryOptions(countryGet.data.map((country): SimpleOption => (
        { label: country.name, value: country[valueKey] }
      )));
    }
  }, [countryGet.data]);

  return (
    <Form.Item
      required={required}
      name={name}
      rules={rules}
      extra={children}
    >
      <Select
        label={label || 'Country'}
        loading={countryGet.loading}
        options={countryOptions}
        search
        {...props}
      />
    </Form.Item>
  );
}

export default SelectCountry;
