import { useEffect, useState } from 'react';
import { Select as AntdSelect, FormInstance } from 'antd';
import clsx from 'clsx';
import floatStyles from '../index.module.scss';
import { useHSCodeGetAll } from '../../../../../../hooks/api/order';
import styles from './index.module.scss';

interface SelectTagsProps {
  label: string;
  value?: string;
  required?: boolean;
  onChange?: (value: string | undefined) => void;
  search?: boolean
  form: FormInstance
  name: number
}

/* eslint-disable max-len */
const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

function SelectTags({
  label, value, required, onChange, search, form, name,
}: SelectTagsProps) {
  const hsCodeGetAll = useHSCodeGetAll();
  const [focus, setFocus] = useState(false);
  const isOccupied = focus || value;

  useEffect(() => {
    hsCodeGetAll.fetch();
  }, []);

  return (
    <label
      className={floatStyles.floatLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <AntdSelect
        variant="borderless"
        size="large"
        showSearch={search}
        value={value || undefined}
        filterOption={filterOption}
        className={clsx(floatStyles.input, styles.select)}
        // onChange={onChange}
        onChange={(values) => {
          const fields = form.getFieldValue('goods');

          fields[name].hsCode = values?.[values.length - 1];
          form.setFieldsValue({ goods: fields });
        }}
        mode="tags"
        options={hsCodeGetAll.data || []}
      />
      <span className={clsx(floatStyles.label, isOccupied ? floatStyles.asLabel : floatStyles.asPlaceholder)}>
        {label}
        {' '}
        {required ? <span className="text-danger">*</span> : null}
      </span>
    </label>
  );
}

SelectTags.defaultProps = {
  value: '',
  search: false,
  required: false,
  onChange: () => {},
};

export default SelectTags;
