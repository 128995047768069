import { ConfigProvider, App } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import React from 'react';

interface AntdConfigProviderProps {
  children: React.ReactNode
}

export const COLOR_PRIMARY = '#339999';
export const COLOR_PRIMARY_HOVER = 'rgb(7,64,141)';

export default function AntdConfigProvider({ children }: AntdConfigProviderProps): React.ReactNode {
  return (
    <ConfigProvider
      locale={enUS}
      theme={{
        token: {
          fontFamily: 'Source Sans Pro',
          colorPrimary: COLOR_PRIMARY,
          colorLink: COLOR_PRIMARY,
          colorLinkHover: '#457329',
          colorLinkActive: COLOR_PRIMARY,
          borderRadius: 0,
          colorText: '#333',
          fontWeightStrong: 700,
        },
        components: {
          Button: {
            defaultBg: 'transparent',
            // colorPrimary: '#FFFFFF',
            algorithm: true,
            paddingInlineLG: 32,
            contentFontSizeLG: 17,
            fontWeight: 700,
          },
          Input: {
            colorPrimary: '#eb2f96',
            algorithm: true,
            inputFontSize: 18,
            inputFontSizeLG: 18,
            activeBorderColor: 'none',
            hoverBorderColor: 'none',
            activeShadow: 'none',
            activeBg: '#EBF5F5',
            addonBg: '#EBF5F5',
            hoverBg: '#EBF5F5',
          },
        },
      }}
    >
      <App>
        {children}
      </App>
    </ConfigProvider>
  );
}
