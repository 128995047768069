import { Typography } from 'antd';
import clsx from 'clsx';
import styles from './index.module.scss';
import {
  CompleteIcon, DollarIcon, FileIcon, FolderIcon, PhoneIcon, TruckIcon,
} from '../../../../Common/Icon';

export default function Confirmation(): React.ReactNode | null {
  return (
    <div className={styles.wrapper}>
      <Typography.Title className={styles.title} level={1}>Thank you</Typography.Title>
      <Typography.Paragraph className={styles.text}>
        Your order has been successfully received.
      </Typography.Paragraph>
      <Typography.Paragraph className={styles.text}>
        We contact you shortly to confirm the order details
        (regular business hours: Monday - Friday 9-17, except for holidays)
      </Typography.Paragraph>
      <Typography.Title className={styles.subtitle} level={3}>What next?</Typography.Title>
      <div className={styles.items}>

        <div className={clsx(styles.item, styles.rightLineOnly)}>
          <div className={styles.icon}>
            <FileIcon />
          </div>
          Review by Sephyre
        </div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <PhoneIcon />
          </div>
          Details Clarification
        </div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <FolderIcon />
          </div>
          Documents Preparation
        </div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <DollarIcon />
          </div>
          Invoicing & Payments
        </div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <TruckIcon />
          </div>
          Shipping Goods
          {' '}
        </div>
        <div className={clsx(styles.item, styles.leftLineOnly)}>
          <div className={styles.icon}>
            <CompleteIcon />
          </div>
          Order Completed
          {' '}
        </div>
      </div>
    </div>
  );
}
