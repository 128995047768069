import { useState } from 'react';
import { Select as AntdSelect } from 'antd';
import clsx from 'clsx';
import { SelectProps } from 'rc-select/lib/Select';
import { SimpleOption } from '../../../../../../types';

import floatStyles from '../index.module.scss';
import styles from './index.module.scss';

export interface SelectFloatProps {
  label: string;
  value?: string;
  required?: boolean;
  onChange?: SelectProps['onChange']; // (value: string | undefined) => void;
  options: SimpleOption[];
  search?: boolean;
  loading?: boolean;
}

/* eslint-disable max-len */
const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

function Select({
  label, value, required, onChange, options, search, loading = false,
}: SelectFloatProps) {
  const [focus, setFocus] = useState(false);
  const isOccupied = focus || value;

  return (
    <label
      className={floatStyles.floatLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <AntdSelect
        variant="borderless"
        size="large"
        showSearch={search}
        value={value || undefined}
        filterOption={filterOption}
        className={clsx(floatStyles.input, styles.select)}
        onChange={onChange}
        options={options}
        loading={loading}
      />
      <span className={clsx(floatStyles.label, isOccupied ? floatStyles.asLabel : floatStyles.asPlaceholder)}>
        {label}
        {' '}
        {required ? <span className="text-danger">*</span> : null}
      </span>
    </label>
  );
}

Select.defaultProps = {
  value: '',
  search: false,
  required: false,
  onChange: () => {},
};

export default Select;
