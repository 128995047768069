import { useState } from 'react';
import clsx from 'clsx';
import PhoneInput from 'react-phone-number-input';
import { E164Number } from 'libphonenumber-js';

import 'react-phone-number-input/style.css';
import floatStyles from '../index.module.scss';

interface InputProps {
  label: string;
  value?: string;
  required?: boolean;
  onChange?: (value: E164Number | undefined) => void;
}

function InputPhone({
  label, value, required, onChange = () => undefined,
}: InputProps) {
  const [focus, setFocus] = useState(false);
  const isOccupied = focus || (value && value.length > 0);

  return (
    <div>
      <PhoneInput
        className={focus || value ? 'custom-phone-input' : 'hidden'}
        value={value}
        onChange={onChange}
        limitMaxLength
        international
        withCountryCallingCode
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <label className={clsx(floatStyles.label, isOccupied ? floatStyles.asLabel : floatStyles.asPlaceholder)}>
        {label}
        {' '}
        {required ? <span className="text-danger">*</span> : null}
      </label>
    </div>
  );
}

export default InputPhone;
