import {
  DefaultFetchError,
  useFetchGet,
  FetchGet,
} from '../fetch';

export interface Company {
id: string
companyName: string
}

interface CompanyParams {
  search: string
}

export function useCompaniesGet<D = Company[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, CompanyParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, CompanyParams, DD>('companies/plain/small', {
    decorateData,
    autoStart: false,
    authorization: false,
    startStateLoading: false,
  });
}
