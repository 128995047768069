import { Typography } from 'antd';
import { CalendarOutlined, EnvironmentFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { useContextForm } from '../Context';
import ShippingSelect from './ShippingSelect';
import Item from './Item';

export default function Sider(): React.ReactNode | null {
  const { current, formData } = useContextForm();

  return (
    current < 4 ? (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Typography.Title className={styles.title} level={3}>Order details</Typography.Title>

          {current === 0 && <Typography.Text>Please fill out the form to place an order</Typography.Text>}

          {(current === 1 || current === 2) && formData?.shipper && (
          <div>
            <ShippingSelect />
            <div className={styles.items}>
              <Item
                icon={<EnvironmentFilled className={styles.icon} />}
                title="Shipper / Pick-up address"
                content={[
                  formData?.shipper?.city,
                  // formData?.shipper?.address1,
                  formData?.shipper?.pickupCity || '',
                  // formData?.shipper?.pickupAddress1 || '',
                ]}
              />

              {formData?.shipper?.pickupDateFrom
                && (
                  <Item
                    icon={<CalendarOutlined className={styles.icon} />}
                    title="Pick-up date & Time"
                    content={[
                      dayjs(formData?.shipper?.pickupDateFrom).utc().format('DD/MM/YYYY'),
                      // eslint-disable-next-line max-len
                      `${dayjs(formData?.shipper?.pickupDateFrom).utc().format('HH:mm')}  -  ${dayjs(formData?.shipper?.pickupDateTo).utc().format('HH:mm')}`,
                    ]}
                  />
                )}
            </div>
          </div>
          )}

          {current === 2 && formData?.importer && (
          <Item
            icon={<EnvironmentFilled className={styles.icon} />}
            title="Importer / Delivery address"
            content={[
              formData?.importer?.city,
              // formData?.importer?.address1,
              formData?.importer?.deliveryCity || '',
              // formData?.importer?.deliveryAddress1 || '',
            ]}
          />
          )}

          {current === 3 && <Typography.Text>Check the details before placing an order</Typography.Text>}

        </div>
      </div>
    ) : null
  );
}
