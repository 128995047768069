import styles from './index.module.scss';

interface DateTimeProps {
  date: string,
  time: string
}

export default function DateTime({ date, time }: DateTimeProps): React.ReactNode | null {
  return (

    <div>
      <div className={styles.date}>{date}</div>
      <div className={styles.time}>{time}</div>
    </div>

  );
}
