import React from 'react';
import FormProvider from './Context';
import Wrapper from './Wrapper';
import Sider from './Sider';

import styles from './index.module.scss';

export default function Form(): React.ReactNode | null {
  return (
    <FormProvider>
      <div className={styles.wrapper}>
        <Sider />
        <Wrapper />
      </div>
    </FormProvider>
  );
}
