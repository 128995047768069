export enum UserStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  PENDING = 'pending',
  ARCHIVED = 'archived',
}

export enum Role {
  GUEST = 'guest',
  ADMIN = 'admin',
  ROOT = 'root',
}

export const ROLE_LIST = [Role.GUEST, Role.ADMIN, Role.ROOT];

export function isRoleEnough(
  currentRole: Role | string | undefined,
  requiredRole: Role | string,
): boolean {
  if (currentRole === requiredRole) {
    return true;
  }

  return (
    ROLE_LIST.indexOf(currentRole as Role)
    >= ROLE_LIST.indexOf(requiredRole as Role)
  );
}
