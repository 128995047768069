import styles from './index.module.scss';

interface LabelProps {
  title: string
  icon: JSX.Element
}
export default function Label({ title, icon } : LabelProps): React.ReactNode | null {
  return (
    <div className={styles.label}>
      {icon}
      {title}
    </div>
  );
}
