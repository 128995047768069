import {
  Button, Form, Input, Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useContextTracking } from '../Context';
import Plate from '../Components/Plate';
import Timeline from '../Components/Timeline';
import {
  useTrackingDbschenker,
  useTrackingDhl,
  useTrackingFedex,
  useTrackingOrder,
} from '../../../../hooks/api/order';
import Details from '../Components/Details';
import styles from './index.module.scss';

export default function Wrapper(): React.ReactNode | null {
  const {
    setOrder, order, setTracking, setTrackingNumber,
  } = useContextTracking();
  const [showResult, setShowResult] = useState(false);
  const dhlGet = useTrackingDhl();
  const fedexGet = useTrackingFedex();
  const dbschenkerGet = useTrackingDbschenker();
  const trackingOrder = useTrackingOrder();
  const [form] = Form.useForm();
  const { trakingnumber } = useParams();
  const loading = dbschenkerGet.loading || dhlGet.loading || fedexGet.loading;

  const getDeliveryService = (service: string, num: string) => {
    switch (service) {
      case 'dhl':
        dhlGet.fetch(undefined, num);
        break;
      case 'fedex':
        fedexGet.fetch(undefined, num);
        break;
      case 'schenker':
        dbschenkerGet.fetch(undefined, num);
        break;

      default:
        return false;
        break;
    }
  };

  const fetchTrackingOrder = (trackingnumber? : string) => {
    const tracking = trackingnumber || form.getFieldValue('tracking');

    form
      .validateFields()
      .then(() => {
        setTrackingNumber(tracking);
        trackingOrder.fetch(undefined, tracking);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (trakingnumber) {
      form.setFieldValue('tracking', trakingnumber);
      fetchTrackingOrder(trakingnumber);
    }
  }, [trakingnumber]);

  useEffect(() => {
    if (dhlGet.data) {
      setTracking(dhlGet.data);
      dhlGet.clearResponse();
    }
    if (fedexGet.data) {
      setTracking(fedexGet.data);
      fedexGet.clearResponse();
    }
    if (dbschenkerGet.data) {
      setTracking(dbschenkerGet.data);
      dbschenkerGet.clearResponse();
    }
  }, [dhlGet.data, fedexGet.data, dbschenkerGet.data]);

  useEffect(() => {
    if (trackingOrder.data) {
      const { data } = trackingOrder;

      setOrder(data);
      setShowResult(true);
      if (data?.deliveryService) {
        getDeliveryService(data.deliveryService, data.trackingNumber);
      }
    } else {
      setOrder(null);
    }
  }, [trackingOrder.data]);

  useEffect(() => {
    if (trackingOrder.error) {
      setOrder(null);
      setShowResult(true);
      setTracking(null);
    }
  }, [trackingOrder.error]);

  return (
    <div className={styles.wrapper}>
      <Typography.Title className={styles.title}>
        Tracking shipment
      </Typography.Title>
      <Form form={form}>
        <div className={styles.form}>
          <Form.Item
            name="tracking"
            rules={[
              { required: true, message: 'Please provide tracking number.' },
            ]}
          >
            <Input
              className={styles.input}
              placeholder="Enter your tracking number"
            />
          </Form.Item>
          <Button
            className={styles.btn}
            size="large"
            loading={trackingOrder.loading || loading}
            onClick={() => fetchTrackingOrder()}
            type="primary"
          >
            TRACK SHIPMENT
          </Button>
        </div>
      </Form>

      {showResult ? (
        <div className={styles.result}>
          <Plate fetchTrackingOrder={fetchTrackingOrder} />

          {order && order.deliveryService === 'other' && (
            <div className={styles.otherText}>
              <div>{`Your shipment is delivered by the ${order.serviceName} delivery service.`}</div>
              {order.trackingLink && (
                <div>
                  {'Please follow this link '}
                  <a
                    href={order.trackingLink}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!order?.trackingLink) {
                        return null;
                      }
                      const url = order.trackingLink.startsWith('http')
                        ? order.trackingLink : `http://${order.trackingLink}`;

                      window.open(url, '_blank', 'noreferrer');
                    }}

                  >
                    {order.trackingLink}
                  </a>
                  {' to track the progress of your shipment.'}
                </div>
              )}
            </div>
          )}
          {order && (
            <>
              <Details />
              <Timeline />
            </>
          )}
          {showResult && !order && (
            <p className={styles.notFound}>
              Item is not found at this moment. If necessary, confirm the
              tracking number with Sephyre or try again later.
            </p>
          )}
        </div>
      ) : (
        <div className={styles.contactInfo}>
          <Typography.Text className={styles.infoText}>
            Enter the tracking number you received in the email from Sephyre.
          </Typography.Text>
          <Typography.Text className={styles.infoText}>
            If you have not received an email, please contact us
            <a href="tel:41619111616"> +4161 9111616</a>
          </Typography.Text>
        </div>
      )}
    </div>
  );
}
