import styles from './index.module.scss';

interface InfoProps {
  status: string,
  address: string
  description: string
  checked: boolean
}

export default function Info({
  status, address, description, checked,
}: InfoProps): React.ReactNode | null {
  return (

    <div className={checked ? styles.checked : ''}>
      <div className={styles.status}>
        {status}
        <br />
        {description}
      </div>
      <div className={styles.address}>{address}</div>
    </div>

  );
}
