// @ts-nocheck
import React, { useMemo } from 'react';
import {
  createSearchParams, NavLink, useLocation,
} from 'react-router-dom';
import { Menu as AntdMenu } from 'antd';
import { Route } from '../../../routes';
import styles from './index.module.scss';
import Form from '../../../pages/Form';

interface User {
  data: string
}

export interface AnyObject {
  [key: string | number]: AnyObject | string | number | boolean | null | any;
}

export interface MenuRoute extends Route {
  ignoreMenu?: boolean;

  children?: MenuRoute[];
}

export const routes: MenuRoute[] = [
  {
    ignoreMenu: true,
    privateRoute: false,
    name: 'Order Form',
    align: 'left',
    route: {
      path: '/',
      element: <Form />,
    },
  },
  // {
  //   ignoreMenu: true,
  //   privateRoute: false,
  //   name: 'Tracking shipment',
  //   align: 'left',
  //   route: {
  //     path: 'tracking',
  //     element: <Form />,
  //   },
  // },

];

export const paramsToString = (user?: User | null, params?: ((user: User) => string) | AnyObject | string) => {
  if (!user) {
    return '';
  }

  if (typeof params === 'string') {
    return '?params';
  }

  if (typeof params === 'function') {
    let result = params(user);

    if (result) {
      if (typeof result === 'object') {
        result = createSearchParams(result).toString();
      }

      return `?${result}`;
    }

    return '';
  }

  if (params && typeof params === 'object') {
    return `?${createSearchParams(params).toString()}`;
  }

  return '';
};

const createItem = ({
  route, children, ignoreMenu, privateRoute, ...data
}: Route, user: User) => {
  let childrenList;

  if (children && children.length > 0) {
    childrenList = children.filter(({ ignoreMenu: childrenIgnoreMenu }) => childrenIgnoreMenu !== true);

    if (childrenList.length > 0) {
      childrenList = childrenList.map(
        ({ ignoreMenu: childrenIgnoreMenu, privateRoute: childrenPrivateRoute, ...child }) => ({
          ...child,
          label: child.route ? (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <NavLink to={`/${child.route.path}${paramsToString(user, child.route.path)}`}>
              {child.name || child.key}
            </NavLink>
          ) : (
            child.name
          ),
          key: child.route ? `/${child.route.path}` : `/${child.key || child.name}`,
        }),
      );
    }
  }

  const name = typeof data.name === 'function' ? data.name(user) : data.name;

  return {
    ...data,
    children: childrenList && childrenList.length > 0 ? childrenList : undefined,
    label: route ? (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      <NavLink to={`/${route.path}${paramsToString(user, route.params)}`}>{name}</NavLink>
    ) : (
      name
    ),
    key: route ? `/${route.path}` : `/${data.key || name}`,
  };
};

export const menuLeft = routes.filter(({ ignoreMenu }) => ignoreMenu !== true).filter(({ align }) => align === 'left');

export const menuRight = routes
  .filter(({ ignoreMenu }) => ignoreMenu !== true)
  .filter(({ align }) => align === 'right');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filter(list: any[], user: User) {
  const newList = list.map((item) => ({ ...item, children: item.children ? [...item.children] : undefined }));

  return newList.filter((item) => {
    if (item && item.children && item.children.length > 0) {
      // eslint-disable-next-line no-param-reassign
      item.children = filter(item.children, user);
    }

    return true;
  });
}

export interface MenuProps {
  user: User;
}

function Menu({ user }: MenuProps): React.ReactNode {
  const { pathname } = useLocation();
  const menuLeftFiltered = useMemo(
    () => filter(
      menuLeft.map((data) => createItem(data, user)),
      user,
    ).map((item) => ({
      ...item,
      name: typeof item.name === 'function' ? item.name(user) : item.name,
      icon: typeof item.icon === 'function' ? item.icon(user) : item.icon,
    })).map(({ children, ...item }) => item),
    [user],
  );
  const menuRightFiltered = useMemo(() => {
    const list = filter(
      menuRight.map((data) => createItem(data, user)),
      user,
    ).map((item) => ({
      ...item,
      name: typeof item.name === 'function' ? item.name(user) : item.name,
      icon: typeof item.icon === 'function' ? item.icon(user) : item.icon,
    }));

    return list;
  }, [user]);

  const activeMenuItem = `/${pathname.split('/')[1]}`;

  return (
    <div className={styles.menu}>
      <AntdMenu
        theme="light"
        mode="horizontal"
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        defaultSelectedKeys={[activeMenuItem]}
        items={menuLeftFiltered}
        style={{ minWidth: 0, flex: 'auto' }}
      />
      <div style={{ justifyContent: 'end', paddingRight: 16, display: 'flex' }} />
    </div>
  );
}
