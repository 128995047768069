import {
  Row, Col, Typography, Form, Button, Flex, Checkbox, Input as AntdInput,
} from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Input from '../../Components/Float/Input';
import { useContextForm } from '../../Context';
import { createRulesForAntd, validationShipping } from '../../../../../utils/validations';
import CustomCheckboxSwitch from '../../Components/CustomCheckbox';
import SelectPlace from '../../Components/Float/SelectPlace';

import styles from './index.module.scss';
import InputPhone from '../../Components/Float/InputPhoneNumber';

const validationRules = createRulesForAntd(validationShipping);

export default function Importer(): React.ReactNode | null {
  const {
    setCurrent, current, setForm, formData,
  } = useContextForm();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [isValid, setValid] = useState(false);
  const countryMainWatch = Form.useWatch('country', form);

  const onFieldsChange = (currentField: any, allFields: any[]) => {
    if (allFields.some((field) => field.errors.length)) {
      setValid(() => false);
    } else {
      setValid(() => true);
    }

    if (currentField?.[0]?.name?.[0] === 'isDeliveryAddressDifferent' && !currentField?.[0]?.value) {
      form.validateFields();
    }
  };

  useEffect(() => {
    if (formData?.importer) {
      form.setFieldsValue({
        ...formData.importer,
        phone: formData.importer.phone || '',
      });
      setValid(true);
    }
  }, [formData?.importer]);

  const importerPlaces = {
    country: 'country',
    postalCode: 'postalCode',
    city: 'city',
    state: 'state',
    address: 'address1',
    countryCode: 'countryCode',
    stateCode: 'stateCode',

  };
  const deliveryPlaces = {
    country: 'deliveryCountry',
    postalCode: 'deliveryPostalCode',
    city: 'deliveryCity',
    state: 'deliveryState',
    address: 'deliveryAddress1',
    countryCode: 'deliveryCountryCode',
    stateCode: 'deliveryStateCode',
  };

  useEffect(() => {
    if (countryMainWatch) {
      form.setFieldValue('deliveryCountry', countryMainWatch);
      form.setFieldValue('deliveryCountryCode', form.getFieldValue('countryCode'));
      ['deliveryAddress1', 'deliveryAddress2', 'deliveryPostalCode', 'deliveryCity', 'deliveryState']
        .forEach((key) => {
          form.setFieldValue(key, undefined);
        });
    }
  }, [countryMainWatch]);
  useEffect(() => {
    if (values?.isDeliveryAddressDifferent) {
      const fieldsToSync = {
        deliveryContactName: 'contactName',
        deliveryCompany: 'company',
        deliveryPhone: 'phone',
        deliveryEmail: 'email',
      };

      Object.entries(fieldsToSync).forEach(([deliveryField, sourceField]) => {
        if (!form.getFieldValue(deliveryField)) {
          form.setFieldValue(deliveryField, form.getFieldValue(sourceField));
        }
      });
    }
  }, [values?.isDeliveryAddressDifferent]);

  useEffect(() => {
    if (formData?.importer) {
      form.setFieldsValue(formData.importer);
      setValid(() => true);
    } else {
      form.setFieldsValue({
        isDeliveryAddressDifferent: false,
      });
    }
  }, []);

  const handleStep = (direction: 'next' | 'prev') => {
    if (direction === 'prev') {
      setForm('importer', form.getFieldsValue());
      setCurrent(current - 1);
    } else {
      form
        .validateFields()
        .then((validatedValues) => {
          setForm('importer', validatedValues);
          setCurrent(current + 1);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  /** Add prefilled values for form */
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('prefilled') && !formData?.importer) {
      const newValues = {
        countryCode: 'CH',
        stateCode: 'SO',
        contactName: 'Importer-name',
        company: 'importer-company',
        phone: '12345678987',
        email: 'importer@gdasda.com',
        country: 'Switzerland',
        address1: 'Swisscom-Gasse',
        postalCode: '4600',
        city: 'Olten',
        state: 'Solothurn',
        tin: '123',
        registerNumber: '321',
        reference: '321-123',
        isDeliveryAddressDifferent: false,
      };

      form.resetFields();
      setTimeout(() => form.setFieldsValue(newValues), 150);
      setValid(true);
    }
  }, [searchParams.get('prefilled')]);

  return (
    <div className={styles.wrapper}>
      <Form
        onFieldsChange={onFieldsChange}
        form={form}
        autoComplete="off"
        className={styles.form}
      >
        <Typography.Title level={2}>Importer details</Typography.Title>
        <Typography.Paragraph className={styles.required}>
          <span className="text-danger">*</span>
          – fields are required
        </Typography.Paragraph>

        <Row gutter={[{
          xs: 16, sm: 16, md: 16, lg: 24,
        }, 28]}
        >

          <Form.Item hidden name="countryCode">
            <AntdInput />
          </Form.Item>
          <Form.Item hidden name="stateCode">
            <AntdInput />
          </Form.Item>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="contactName" rules={[{ required: true, message: 'Please enter a contact name.' }]}>
              <Input required label="Contact name" maxLength={35} />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="company" rules={[{ required: true, message: 'Please enter a company.' }]}>
              <Input required label="Company" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="phone" rules={[validationRules]}>
              <InputPhone required label="Mobile number" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="email" rules={[validationRules]}>
              <Input required label="Email" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="country" rules={[{ required: true, message: 'Please enter a country.' }]}>
              <SelectPlace
                label="Country"
                value={values?.country}
                type={['country']}
                form={form}
                fields={importerPlaces}
                name="country"
                required
                onSelect={() => {
                  ['address1', 'address2', 'postalCode', 'city', 'state'].forEach((key) => {
                    form.setFieldValue(key, undefined);
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="address1" rules={[{ required: true, message: 'Please enter a address.' }]}>
              <SelectPlace
                label="Address"
                value={values?.address1}
                type={['address']}
                fields={importerPlaces}
                form={form}
                name="address1"
                required
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="address2">
              <Input label="Address 2" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="postalCode" rules={[{ required: true, message: 'Please enter a postal code.' }]}>
              <SelectPlace
                label="Postal code"
                value={values?.postalCode}
                type={['postal_code']}
                fields={importerPlaces}
                form={form}
                name="postalCode"
                required
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="city" rules={[{ required: true, message: 'Please enter a city / town.' }]}>
              <SelectPlace
                label="City / Town"
                value={values?.city}
                type={['locality']}
                fields={importerPlaces}
                form={form}
                name="city"
                required
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item
              name="state"
              rules={[{ required: true, message: 'Please enter a province /region / state.' }]}
            >
              <SelectPlace
                label="Province / Region / State"
                value={values?.state}
                type={['administrative_area_level_1']}
                form={form}
                fields={importerPlaces}
                name="state"
                required
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="tin">
              <Input label="TIN number" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="registerNumber">
              <Input
                label="Register number"
                hint="EORI (for European countries only) EIN, GLN or special company register number"
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="reference">
              <Input
                label="Reference"
                hint="Will be specified on an additional invoice line under the reference or purchase order number"
              />
            </Form.Item>
          </Col>

          <Col sm={24} md={24} xs={24}>
            <Typography.Title level={2}>Delivery details</Typography.Title>
          </Col>

          <Col span={24}>
            <Form.Item valuePropName="checked" name="isDeliveryAddressDifferent">
              <Checkbox className="invisible">
                <CustomCheckboxSwitch
                  title="The Delivery address differ from the Importer's address"
                  checked={values?.isDeliveryAddressDifferent}
                />
              </Checkbox>
            </Form.Item>
          </Col>

          {values?.isDeliveryAddressDifferent && (
            <>
              <Form.Item hidden name="deliveryCountryCode">
                <AntdInput />
              </Form.Item>
              <Form.Item hidden name="deliveryStateCode">
                <AntdInput />
              </Form.Item>

              <Col sm={24} md={12} xs={24}>
                <Form.Item
                  name="deliveryContactName"
                  rules={[{ required: true, message: 'Please enter a contact name.' }]}
                >
                  <Input required label="Contact name" />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item name="deliveryCompany" rules={[{ required: true, message: 'Please enter a company.' }]}>
                  <Input required label="Company" />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item name="deliveryPhone" rules={[validationRules]}>
                  <InputPhone required label="Mobile number" />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item name="deliveryEmail" rules={[validationRules]}>
                  <Input required label="Email" />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item name="deliveryCountry" rules={[{ required: true, message: 'Please enter a country.' }]}>
                  <SelectPlace
                    label="Country"
                    value={values?.country}
                    type={['country']}
                    form={form}
                    fields={deliveryPlaces}
                    name="deliveryCountry"
                    disabled={!!countryMainWatch}
                    required
                    onSelect={() => {
                      ['deliveryAddress1', 'deliveryAddress2', 'deliveryPostalCode', 'deliveryCity', 'deliveryState']
                        .forEach((key) => {
                          form.setFieldValue(key, undefined);
                        });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item name="deliveryAddress1" rules={[{ required: true, message: 'Please enter a address.' }]}>
                  <SelectPlace
                    label="Address"
                    value={values?.deliveryAddress1}
                    type={['address']}
                    fields={deliveryPlaces}
                    form={form}
                    name="deliveryAddress1"
                    required
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item name="deliveryAddress2">
                  <Input label="Address 2" />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item
                  name="deliveryPostalCode"
                  rules={[{ required: true, message: 'Please enter a postal code.' }]}
                >
                  <SelectPlace
                    label="Postal code"
                    value={values?.deliveryPostalCode}
                    type={['postal_code']}
                    fields={deliveryPlaces}
                    form={form}
                    name="deliveryPostalCode"
                    required
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item name="deliveryCity" rules={[{ required: true, message: 'Please enter a city / town.' }]}>
                  <SelectPlace
                    label="City / Town"
                    value={values?.deliveryCity}
                    type={['locality']}
                    fields={deliveryPlaces}
                    form={form}
                    name="deliveryCity"
                    required
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item
                  name="deliveryState"
                  rules={[{ required: true, message: 'Please enter a province /region / state.' }]}
                >
                  <SelectPlace
                    label="Province / Region / State"
                    value={values?.deliveryState}
                    type={['administrative_area_level_1']}
                    form={form}
                    fields={deliveryPlaces}
                    name="deliveryState"
                    required
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item name="deliveryRegisterNumber">
                  <Input
                    label="Register number"
                    hint="EORI (for European countries only) EIN, GLN or special company register number"
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24}>
            <Flex justify="flex-end" gap={8}>
              <Button
                size="large"
                type="default"
                id="backButton"
                onClick={() => {
                  handleStep('prev');
                }}
              >
                BACK
              </Button>
              <Button
                size="large"
                disabled={!isValid}
                type="primary"
                onClick={() => {
                  handleStep('next');
                }}
              >
                CONTINUE TO GOODS
              </Button>
            </Flex>
          </Col>
        </Row>

      </Form>
    </div>
  );
}
