import React from 'react';
import Simple from '../../components/Layout/Simple';
import TrackingContent from '../../components/Pages/Tracking';

export default function Tracking(): React.ReactNode | null {
  document.title = 'Order Form';

  return (
    <Simple>
      <TrackingContent />
    </Simple>
  );
}
