import { useState, ChangeEvent, useCallback } from 'react';
import { Form, FormInstance, TimePicker } from 'antd';
import clsx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import styles from './index.module.scss';
import floatStyles from '../index.module.scss';

dayjs.extend(customParseFormat);

const disabledStartHours = () => {
  const disabledHoursArr = [];

  for (let i = 0; i < 24; i++) {
    if (i < 7 || i > 19) {
      disabledHoursArr.push(i);
    }
  }

  return disabledHoursArr;
};
const disabledStartMinutes = (selectedHour: number) => {
  if (selectedHour === 19) {
    return Array.from({ length: 60 }, (_, i) => i).filter((minute) => minute !== 0);
  }

  return [];
};

interface TimeProps {
  label: string;
  required?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  form?: FormInstance | undefined;
  timeType?: 'start' | 'end';
  dependencyTime?: string;
}

function Time({
  label, required, onChange, name, form, timeType, dependencyTime,
}: TimeProps) {
  const values = Form.useWatch([], form);
  const [focus, setFocus] = useState(false);
  const isOccupied = focus || values?.[name];

  const disabledHours = useCallback(() => {
    const newStartTime = dependencyTime ? dayjs(dependencyTime) : dayjs();

    const disabledHoursArr = [];
    const startHour = newStartTime.hour();

    for (let i = 0; i < Math.min(startHour + 2, 23); i++) {
      disabledHoursArr.push(i);
    }

    return disabledHoursArr;
  }, [dependencyTime]);

  const disabledMinutes = useCallback((selectedHour: number) => {
    if (!dependencyTime) {
      return [];
    }

    const newStartTime = dayjs(dependencyTime).clone();
    const startHour = newStartTime.hour();
    const startMinute = newStartTime.minute();

    /** Always allow to select 23:59. */
    if (startHour === 22 || startHour === 23) {
      return Array.from({ length: 60 }, (_, i) => i)
        .filter((item) => item !== 59);
    }

    /** If the selected hour is exactly 2 hours after the start hour, disable minutes less than the start minute */
    if (selectedHour === startHour + 2) {
      return Array.from({ length: startMinute }, (_, i) => i);
    }

    /** Disable all minutes if the selected hour is within the 2-hour gap */
    if (selectedHour < startHour + 2) {
      return Array.from({ length: 60 }, (_, i) => i);
    }

    return [];
  }, [dependencyTime]);

  return (
    <div
      className={floatStyles.floatLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Form.Item name={name}>
        <TimePicker
          className={clsx(styles.input, floatStyles.input)}
          allowClear={false}
          placeholder=""
          minuteStep={15}
          format="HH:mm"
          showNow={false}
          needConfirm={false}
          disabledTime={() => {
            if (timeType === 'start') {
              return {
                disabledHours: disabledStartHours,
                disabledMinutes: disabledStartMinutes,
              };
            }
            if (timeType === 'end' && dependencyTime) {
              return {
                disabledHours,
                disabledMinutes,
              };
            }

            return {};
          }}
        />
      </Form.Item>
      <label className={clsx(styles.label, floatStyles.label, isOccupied ? styles.asLabel : floatStyles.asPlaceholder)}>
        {label}
        {' '}
        {required ? <span className="text-danger">*</span> : null}
      </label>
    </div>
  );
}

Time.defaultProps = {
  required: false,
  onChange: () => {},
  form: undefined,
};

export default Time;
