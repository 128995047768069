import React, { useState } from 'react';
import { Drawer } from 'antd';

import styles from './index.module.scss';

const links = [
  { text: 'Ship now', link: 'https://www.sephyre.com/ship-now/' },
  { text: 'Services', link: 'https://www.sephyre.com/#services' },
  { text: 'Clients', link: 'https://www.sephyre.com/#clients' },
  { text: 'Feedback', link: 'https://www.sephyre.com/#feedback' },
  { text: 'Testimonials', link: 'https://www.sephyre.com/testimonials/' },
  { text: 'Contact', link: 'https://www.sephyre.com/#footer' },
];

export default function Header(): React.ReactNode {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className={styles.header}>
      <div>
        <a href="https://www.sephyre.com/">
          <img src="/media/logo-sephyre.png" alt="Logo" className={styles.logo} />
        </a>
      </div>
      <div className={styles.links}>
        {links.map((item) => (
          <a key={item.text} href={item.link} target="_blank" rel="noreferrer">
            {item.text}
          </a>
        ))}
      </div>
      <div className={styles.menu}>
        <div className={styles.menuButton}>
          <img src="/media/menu-image.png" alt="menu" onClick={showDrawer} />
        </div>
        <Drawer
          placement="right"
          closable={false}
          onClose={onClose}
          open={visible}
          style={{ backgroundColor: '#339999' }}
          width={200}
        >
          <div className={styles.menuItems}>
            {links.map((item) => (
              <a key={item.text} href={item.link} target="_blank" rel="noreferrer">
                {item.text}
              </a>
            ))}
          </div>
        </Drawer>

      </div>
    </div>
  );
}
