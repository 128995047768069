import React from 'react';

import styles from './index.module.scss';

const links = [
  { text: 'Terms & Conditions', link: 'https://www.sephyre.com/terms-conditions/' },
  { text: 'Shipping Terms', link: 'https://www.sephyre.com/shipping-terms/' },
  { text: 'Data privacy', link: 'https://www.sephyre.com/data-privacy/' },
  { text: 'Imprint', link: 'https://www.sephyre.com/imprint/' },
];

export default function Footer(): React.ReactNode {
  return (
    <div className={styles.footer}>
      <div className={styles.footerInfo}>
        <div className={styles.items}>
          <span>Sephyre GmbH</span>
          <span>PO Box</span>
          <span>CH-4450 Sissach</span>
          <span>Switzerland</span>
          <a href="tel:+41619111616" style={{ marginTop: '30px' }}>+41 61 911 16 16</a>
          <a href="mailto:welcome@sephyre.com">welcome@sephyre.com</a>
        </div>
        <div className={styles.social}>
          <a href="https://www.instagram.com/sephyrelogistics/">
            <img src="/media/ic-instagram.png" alt="logo" className={styles.logo} />
          </a>
          <a href="https://www.linkedin.com/company/sephyre-logistics">
            <img src="/media/linkedin.png" alt="Logo" className={styles.logo} />
          </a>
        </div>
        <div className={styles.linkBlock}>
          {links.map((item) => (
            <a key={item.text} href={item.link} target="_blank" rel="noreferrer">
              {item.text}
            </a>
          ))}
          <span>© 2024 Sephyre GmbH</span>
        </div>
      </div>
    </div>
  );
}
