import { Switch, SwitchProps } from 'antd';
import styles from './index.module.scss';

interface CustomCheckboxProps extends Omit<SwitchProps, 'title'> {
  title: string | React.ReactNode;
  callback?: (value: boolean) => void
  checked: boolean
}

function CustomCheckboxSwitch({
  title, callback, checked, value,
} : CustomCheckboxProps) {
  return (
    <div className={styles.wrapper}>
      <Switch
        className={styles.noClickable}
        checked={checked}
        // value={value}
        onChange={(check) => {
          if (callback) {
            callback(check);
          }
        }}
      />
      {title}
    </div>
  );
}

CustomCheckboxSwitch.defaultProps = {
  callback: () => {},
};

export default CustomCheckboxSwitch;
