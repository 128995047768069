import { Typography } from 'antd';
import styles from './index.module.scss';

interface ItemProps {
  icon: JSX.Element
  title: string
  content: string[]
}

export default function Item({ icon, title, content }: ItemProps): React.ReactNode | null {
  return (

    <div className={styles.item}>
      {icon}
      <div className={styles.content}>
        <Typography.Text className={styles.itemTitle}>{title}</Typography.Text>
        {content.map((text) => (
          <Typography.Text className={styles.text} key={`${text}${Math.random()}`}>
            {text}
          </Typography.Text>
        ))}
      </div>
    </div>
  );
}
