import { Button, Space } from 'antd';
import { useState } from 'react';
import { useContextTracking } from '../../Context';
import { DropdownIcon } from '../../../../Common/Icon';
import styles from './index.module.scss';

export default function Details(): React.ReactNode | null {
  const { order } = useContextTracking();
  const [showDetails, setDetails] = useState(false);

  if (!order) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.panel}>
        <Button
          className={styles.btn}
          type="text"
          onClick={() => setDetails(!showDetails)}
        >
          <Space align="center">
            {showDetails ? 'Hide shipment details' : 'Show shipment details'}
            <DropdownIcon className={showDetails ? styles.active : undefined} />
          </Space>
        </Button>

        {order.deliveryService && (
          <p className={styles.shipment}>
            {order.deliveryService === 'other' ? order.serviceName : order.deliveryService}
            {' shipment tracking'}
          </p>
        )}
      </div>

      {showDetails && (
        <div className={styles.wrapper}>
          <div className={styles.mainHeader}>Shipment details</div>
          <div className={styles.inner}>
            <div className={styles.header}>
              <span>From</span>
              <span className={styles.desktop}>To</span>
            </div>

            <div className={styles.row}>
              <div className={styles.col}>
                <span>{order.shipper?.contactName}</span>
                <span>{order.shipper?.company}</span>
                <span>{order.shipper?.phone}</span>
                <span>{order.shipper?.email}</span>
                <span>{order.shipper?.address1}</span>
                <span>{order.shipper?.address2 || ''}</span>
                <span>{order.shipper?.postalCode || ''}</span>
                <span>{order.shipper?.city}</span>
                <span>{order.shipper?.country}</span>
              </div>

              <div className={styles.mobileHeader}>
                <div className={styles.header}>
                  <span>To</span>
                </div>
              </div>

              <div className={styles.col}>
                <span>{order.importer?.contactName}</span>
                <span>{order.importer?.company}</span>
                <span>{order.importer?.phone}</span>
                <span>{order.importer?.email}</span>
                <span>{order.importer?.address1}</span>
                <span>{order.importer?.address2 || ''}</span>
                <span>{order.importer?.postalCode}</span>
                <span>{order.importer?.city}</span>
                <span>{order.importer?.country}</span>
              </div>
            </div>

            {order.goods
              && order.goods.map((item, i) => (
                <div key={item.id}>
                  <div className={styles.header}>
                    <span>{`№${i + 1}`}</span>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.col}>
                      <span>
                        {item.quantity && `${item.quantity}x `}
                        {item.packaging?.name && `${item.packaging?.name} `}
                        {item.packagingGroup && `${item.packagingGroup}`}
                      </span>

                      <span className={styles.mt}>
                        {item.unNumber && item.unNumber}
                      </span>
                      <span>{item.hsCode && item.hsCode}</span>
                      <span>{item.description && item.description}</span>

                      {item.innerPackaging && item.innerPackagingType && (
                        <span className={styles.mt}>
                          Inner packaging:
                          {item.innerPackagingQuantity
                            && `${item.innerPackagingQuantity} x `}
                          {item.innerPackagingType.name
                            && `${item.innerPackagingType.name} `}
                          {item.innerPackagingNetUnitaryQuantity
                            && `${item.innerPackagingNetUnitaryQuantity} kg`}
                        </span>
                      )}
                    </div>
                    <div className={styles.col}>
                      {item.value && (
                        <span>{`${item.value} ${item.currency}`}</span>
                      )}
                      {item.net && (
                        <span>{`Net ${item.net} ${item.massUnit}`}</span>
                      )}
                      {item.gross && <span>{`Gross ${item.gross} kg`}</span>}
                      {item?.length && item.width && item.height && (
                        <span>
                          {`L${item?.length} x W${item.width} x H${item.height} cm`}
                        </span>
                      )}
                      {item.volume && <span>{`${item.volume} m3`}</span>}
                    </div>
                  </div>
                </div>
              ))}

            <div className={styles.footer}>
              <div className={styles.qty}>
                <span>{`${order.goods.length || 0} `}</span>
                unit(s)
              </div>
              <div className={styles.info}>
                <div>
                  Net (total):
                  <span>
                    {` ${order?.goods?.reduce((sum: number, curr: any) => {
                      // Math.round(num * 100) / 100
                      const net = Number(curr?.net);
                      const quantity = Number(curr?.quantity);
                      const innerNet = Number(curr?.innerPackagingNetUnitaryQuantity);
                      const innerQty = Number(curr?.innerPackagingQuantity);
                      const innerPackaging = curr?.innerPackaging;

                      if (!net && !innerNet) {
                        const result = 0 + sum;

                        return Math.round(result * 100) / 100;
                      }

                      if (innerPackaging && innerQty && innerNet) {
                        const result = (((quantity || 1) * ((innerQty || 1) * (innerNet || 1)) || 0) + sum);

                        return Math.round(result * 100) / 100;
                      }

                      if (innerPackaging && innerQty) {
                        const result = (((quantity || 1) * (innerQty || 1) * net || 0) + sum);

                        return Math.round(result * 100) / 100;
                      }

                      const result = ((quantity || 1) * net || 0) + sum;

                      return Math.round(result * 100) / 100;
                    }, 0)} kg`}
                  </span>
                </div>
                <div>
                  Gross (total):
                  <span>
                    {` ${order?.goods?.reduce((sum: number, curr: any) => {
                      if (!Number(curr?.gross)) {
                        return 0 + sum;
                      }

                      return (
                        ((Number(curr?.quantity) || 1) * Number(curr?.gross)
                          || 0) + sum
                      );
                    }, 0)} kg`}
                  </span>
                </div>
                <div>
                  Volume:
                  <span>
                    {` ${Number.parseFloat(order?.goods?.reduce((sum: number, curr: any) => {
                      if (curr?.height && curr?.length && curr?.width && Number(curr?.quantity)) {
                        return (
                          ((curr.height * curr.length * curr.width) / 1000000) * (Number(curr?.quantity) || 1)
                          + sum
                        );
                      }
                      if (curr?.height && curr?.length && curr?.width) {
                        return (
                          (curr.height * curr.length * curr.width) / 1000000
                          + sum
                        );
                      }

                      return sum;
                    }, 0).toFixed(3))} m3`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
