import { Button, Typography } from 'antd';
import clsx from 'clsx';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  DirectionIcon,
  NotFoundIcon,
  OnTheWayIcon,
  RefreshIcon,
  TrackingIcon,
} from '../../../../Common/Icon';
import { useContextTracking } from '../../Context';
import { capitalize } from '../../../../../utils';
import dhlLogo from '../../../../../resource/images/dhl.png';
import fedexLogo from '../../../../../resource/images/fedex.jpg';
import dbschenkerLogo from '../../../../../resource/images/dbschenker.jpg';
import TrackingStatus from '../../../../../enums/tracking';
import styles from './index.module.scss';

interface PlateProps {
  fetchTrackingOrder: () => void;
}

export default function Plate({
  fetchTrackingOrder,
}: PlateProps): React.ReactNode | null {
  const { order, trackingNumber, tracking } = useContextTracking();

  const getLogo = () => {
    switch (order?.deliveryService) {
      case 'dhl':
        return <img src={dhlLogo} alt="" />;
      case 'fedex':
        return <img src={fedexLogo} alt="" />;
      case 'schenker':
        return <img src={dbschenkerLogo} alt="" />;

      default:
        return <TrackingIcon />;
    }
  };

  const getStatus = () => {
    switch (tracking?.shipments?.[0]?.status.toLowerCase()) {
      case TrackingStatus.ON_THE_WAY:
        return <OnTheWayIcon />;

      case TrackingStatus.DELIVERED:
        return <CheckCircleOutlined style={{ color: '#339999' }} />;

      case TrackingStatus.ALERT:
        return <ExclamationCircleOutlined style={{ color: '#FAAD14' }} />;

      case TrackingStatus.NOT_DELIVERED:
        return <CloseCircleOutlined style={{ color: '#FF4D4F' }} />;

      case TrackingStatus.EXPIRED:
        return <CloseCircleOutlined style={{ color: '#FF4D4F' }} />;

      default:
        return <OnTheWayIcon />;
    }
  };

  const getClass = () => {
    switch (tracking?.shipments?.[0]?.status.toLowerCase()) {
      case TrackingStatus.ON_THE_WAY:
        return styles.on_the_way;

      case TrackingStatus.DELIVERED:
        return styles.delivered;

      case TrackingStatus.ALERT:
        return styles.alert;

      case TrackingStatus.NOT_DELIVERED:
        return styles.not_delivered;

      case TrackingStatus.EXPIRED:
        return styles.expired;

      default:
        return styles.on_the_way;
    }
  };

  const getStatusName = () => {
    if (!tracking?.shipments?.[0]?.status && order?.status) {
      return order?.status;
    }

    if (order?.deliveryService === 'other') {
      return 'Undefined status';
    }

    if (!tracking) {
      return 'Not found';
    }

    return tracking?.shipments?.[0]?.status;
  };

  return (
    <div className={styles.item}>
      <div className={styles.top}>
        <div className={styles.img}>{getLogo()}</div>
        <div className={styles.number}>
          {trackingNumber || '-'}
          <div className={tracking ? getClass() : ''}>
            {tracking ? getStatus() : <NotFoundIcon />}
            {capitalize(getStatusName())}
          </div>
        </div>
      </div>
      {order && (
        <div className={styles.directions}>
          <div className={clsx(styles.origin, styles.direct)}>
            Origin
            <Typography.Text>{order.shipper.country}</Typography.Text>
          </div>
          <div className={styles.icon}>
            <DirectionIcon />
          </div>
          <div className={clsx(styles.destination, styles.direct)}>
            Destination
            <Typography.Text>{order.importer.country}</Typography.Text>
          </div>
        </div>
      )}
      <Button
        type="text"
        className={styles.refresh}
        onClick={() => fetchTrackingOrder()}
      >
        <RefreshIcon />
        REFRESH
      </Button>
    </div>
  );
}
