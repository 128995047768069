import { Timeline as AntdTimeline } from 'antd';
import dayjs from 'dayjs';
import DateTime from './DateTime';
import Info from './Info';
import { TimelineIcon } from '../../../../Common/Icon';
import { useContextTracking } from '../../Context';
import TrackingStatus from '../../../../../enums/tracking';
import styles from './index.module.scss';

export default function Timeline(): React.ReactNode | null {
  const { tracking } = useContextTracking();

  const getCollor = () => {
    switch (tracking?.shipments?.[0]?.status.toLowerCase()) {
      case TrackingStatus.ON_THE_WAY:
        return '#1677FF';

      case TrackingStatus.DELIVERED:
        return '#399';

      case TrackingStatus.ALERT:
        return '#FAAD14';

      case TrackingStatus.NOT_DELIVERED:
        return '#FF4D4F';

      case TrackingStatus.EXPIRED:
        return '#FF4D4F';

      default:
        return '#FF4D4F';
    }
  };

  return (
    <AntdTimeline
      className={styles.timeline}
      mode="left"
      items={
        tracking?.shipments.map((item, i) => {
          const date = dayjs(item.timestamp);

          return {
            dot:
              i === 0 ? (
                <TimelineIcon
                  style={{ color: getCollor(), fill: getCollor() }}
                  className={styles.dot}
                  width="20"
                  height="20"
                  fill="none"
                />
              ) : null,
            label: (
              <DateTime
                date={date.format('YYYY-MM-DD')}
                time={date.format('HH:mm')}
              />
            ),
            children: (
              <Info
                status={item.status}
                description={item.description}
                address={item.address.name}
                checked={i === 0}
              />
            ),
          };
        }) || []
      }
    />
  );
}
