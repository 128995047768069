import { Typography } from 'antd';
import { FileFilled } from '@ant-design/icons';
import styles from './index.module.scss';

interface FileProps {
  name: string
}

export default function File({ name } : FileProps): React.ReactNode | null {
  return (
    <Typography.Text className={styles.file}>
      <FileFilled />
      {name}
    </Typography.Text>
  );
}
