import React from 'react';
import { Form, SelectProps } from 'antd';
import { NamePath, Rule } from 'rc-field-form/lib/interface';
import Select, { SelectFloatProps } from '../../../Pages/Form/Components/Float/Select';
import { AnyObject } from '../../../../utils';
import { SimpleOption } from '../../../../types';

export interface OrderSelectProps extends Omit<SelectFloatProps, 'options'> {
  prefix?: string;
  rules?: Rule[];
  name: NamePath<string>;
  rest?: AnyObject;
  options?: SimpleOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectProps?: SelectProps<any, any>;
  optionsType?: 'default' | 'preferredDelivery';
}

export const DeliveryServiceList: SimpleOption[] = [
  {
    value: 'dhl',
    label: 'DHL',
  },
  {
    value: 'fedex',
    label: 'Fedex',
  },
  {
    value: 'schenker',
    label: 'DB Schenker',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

const preferredServiceKeys = new Set(['dhl', 'fedex']);

const PreferredServiceList: SimpleOption[] = DeliveryServiceList.filter(({ value }) => (
  preferredServiceKeys.has(value)
));

function SelectDeliveryService({
  label,
  optionsType = 'default',
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps) {
  return (
    <Form.Item
      {...rest}
      {...props}
    >
      <Select
        label={label || 'Select delivery service'}
        allowClear
        showSearch
        options={optionsType === 'preferredDelivery' ? PreferredServiceList : DeliveryServiceList}
        {...selectProps}
        {...rest}
      />
    </Form.Item>
  );
}

export default SelectDeliveryService;
