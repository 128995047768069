import { useState, ChangeEvent } from 'react';
import {
  DatePicker, Form, FormInstance,
} from 'antd';
import clsx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import floatStyles from '../index.module.scss';
import styles from './index.module.scss';
import { CalendarIcon } from '../../../../../Common/Icon';

dayjs.extend(customParseFormat);

interface DateProps {
  label: string;
  required?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  name: string
  form?: FormInstance | undefined
  message?: string
  index?: number | boolean
}

function Date({
  label, required, onChange, name, form, message, index,
}: DateProps) {
  const isIndex = (index === 0 || index);
  const values = Form.useWatch([], form);
  const [focus, setFocus] = useState(false);
  const isOccupied = focus || ((isIndex && values) ? values.goods?.[index as number]?.[name] : values?.[name]);

  return (
    <div
      className={floatStyles.floatLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Form.Item
        name={isIndex ? [index, name] : name}
        rules={required ? [{ required: true, message }] : []}
      >
        <DatePicker
          format="DD/MM/YYYY"
          className={clsx(styles.input, floatStyles.input)}
          allowClear={false}
          placeholder=""
          showNow={false}
          needConfirm={false}
          suffixIcon={<CalendarIcon style={{ fontSize: '30px' }} />}
        />
      </Form.Item>
      <label className={clsx(floatStyles.label, styles.label, isOccupied ? styles.asLabel : floatStyles.asPlaceholder)}>
        {label}
        {' '}
        {required ? <span className="text-danger">*</span> : null}
      </label>
    </div>
  );
}

Date.defaultProps = {
  required: false,
  onChange: () => {},
  form: undefined,
  message: 'Please specify field',
  index: false,
};

export default Date;
