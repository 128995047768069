import {
  DefaultFetchError,
  useFetchGet,
  FetchGet,
} from '../fetch';

export interface Package {
name: string
packageId: string
}

export function usePackagesGet<D = Package[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, null, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, null, DD>('masterdata/packages', {
    name: 'Package',
    decorateData,
    autoStart: false,
    authorization: false,
    startStateLoading: false,
  });
}

export interface UNnumber {
  unNumber: string
  name: string
  dangerousGoodId: string
  packingGroup: string
  classificationCode: string
  labels: string
}

export function useUNnumberGet<D = UNnumber[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, null, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, null, DD>('masterdata/goods', {
    name: 'UNnumber',
    decorateData,
    autoStart: false,
    authorization: false,
    startStateLoading: false,
  });
}

export interface InnerPackage {
  name: string
  innerPackageMaterialId: string
  }

export function useInnerPackageGet<D = InnerPackage[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, null, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, null, DD>('masterdata/innerPackageMaterial', {
    name: 'InnerPackage',
    decorateData,
    autoStart: false,
    authorization: false,
    startStateLoading: false,
  });
}
