import { FormInstance, Switch, SwitchProps } from 'antd';
import styles from './index.module.scss';

interface CustomSwitchProps extends SwitchProps {
  name?: string;
  title: string;
  callback?: (value: boolean) => void
  form?: FormInstance | undefined
  checkForm?: boolean
  checked?: boolean | undefined
}

function CustomSwitch({
  name, title, callback, form, checkForm, checked, value, ...rest
} : CustomSwitchProps) {
  return (
    <div className={styles.wrapper}>
      <Switch
        value={value || checked || undefined}
        onChange={(check) => {
          if (name && form) {
            form.setFieldValue(name, check);
          }

          if (checkForm && form) {
            setTimeout(() => {
              form.validateFields();
            }, 20);
          }

          if (callback) {
            callback(check);
          }
        }}
        {...rest}
      />
      {title}
    </div>
  );
}

CustomSwitch.defaultProps = {
  name: '',
  callback: () => {},
  form: undefined,
  checkForm: false,
  checked: undefined,
};

export default CustomSwitch;
